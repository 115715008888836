import axios from 'axios';
import { getNoAuthRFPApi, deleteApi } from './common/apis';

export default class ChannelService {
    async getChannelData(data) {
        try {
            let response = await getNoAuthRFPApi(`api/v1/channel-details?subnetwork_id=${data}`);
            return await response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }

    async deleteChannel(data) {
        try {
            let response = await deleteApi(`v1/account?accountId=${data}`);
            return await response;
        } catch (err) {
            console.error('Error', err.stack);

            return err.response;
        }
    }
}

